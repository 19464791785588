<template>
  <v-sheet
    elevation="3"
    rounded
    class="mb-4"
  >
    <v-container
      fluid
    >
      <v-row
        dense
      >
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <TypeSelector
            :state.sync="filters['type[]']"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <StatusSelector
            :state.sync="filters['status[]']"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <ProviderSelector
            :state.sync="filters['provider[]']"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <CarrierSelector
            :state.sync="filters['carrier[]']"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <InternalSelector
            :state.sync="filters.internal"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-checkbox
            v-model="filters.has_error"
            class="ml-2"
            dense
            hide-details
          >
            <template v-slot:label>
              Comporte des erreurs
              <v-icon
                small
                right
              >
                mdi-alert-circle-outline
              </v-icon>
            </template>
          </v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <StoreSelector
            v-model="filters.store_id"
            filter
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <ProductSelector
            v-model="filters.product_id"
            filter
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-text-field
            v-model="filters.reference"
            label="Ref. Commande"
            prepend-inner-icon="mdi-cart-variant"
            hide-details
            clearable
            dense
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-text-field
            v-model="filters.tracking_number"
            hide-details
            label="N° de suivi"
            prepend-inner-icon="mdi-package-variant"
            clearable
            dense
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-text-field
            v-model="filters.recipient_email"
            label="Email du Client"
            prepend-inner-icon="mdi-at"
            clearable
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
          lg="1"
        >
          <v-menu
            v-model="datePickers.from.show"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="datePickers.from.value"
                label="Du"
                prepend-inner-icon="mdi-calendar"
                hide-details
                clearable
                dense
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="datePickers.from.value"
              @input="datePickers.from.show = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="2"
          lg="1"
        >
          <v-menu
            v-model="datePickers.to.show"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            left
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="datePickers.to.value"
                label="Au"
                prepend-inner-icon="mdi-calendar"
                hide-details
                clearable
                dense
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="datePickers.to.value"
              @input="datePickers.to.show = false"
            />
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import CarrierSelector from '@/components/base/Shipment/Input/CarrierSelector.vue'
import { debounce } from '@/helpers/debounce'
import { getQueryAsArray } from '@/helpers/queryString'
import InternalSelector from '@/components/base/Shipment/Input/InternalSelector.vue'
import ProductSelector from '@/components/base/Product/Input/ProductSelector.vue'
import ProviderSelector from '@/components/base/Shipment/Input/ProviderSelector.vue'
import StatusSelector from '@/components/base/Shipment/Input/StatusSelector.vue'
import StoreSelector from '@/components/base/StoreSelector.vue'
import TypeSelector from '@/components/base/Shipment/Input/TypeSelector.vue'

export default {
  name: 'ShipmentFilters',
  components: {
    ProductSelector,
    InternalSelector,
    StoreSelector,
    TypeSelector,
    StatusSelector,
    ProviderSelector,
    CarrierSelector,
  },
  data() {
    return {
      filters: {
        store_id: this.$route.query['store_id'],
        internal: this.$route.query['internal'],
        reference: this.$route.query['reference'],
        tracking_number: this.$route.query['tracking_number'],
        recipient_email: this.$route.query['recipient_email'],
        has_error: this.$route.query['has_error'] ?? false,
        'type[]': getQueryAsArray(this.$route.query, 'type[]'),
        'status[]': getQueryAsArray(this.$route.query, 'status[]'),
        'provider[]': getQueryAsArray(this.$route.query, 'provider[]'),
        'carrier[]': getQueryAsArray(this.$route.query, 'carrier[]'),
        'created_at[after]': this.$route.query['created_at[after]'],
        'created_at[before]': this.$route.query['created_at[before]'],
        product_id: this.$route.query['product_id'],
      },
      datePickers: {
        from: { show: false, value: this.$route.query['created_at[after]'] },
        to: { show: false, value: this.$route.query['created_at[before]'] },
      },
    }
  },
  watch: {
    'datePickers.from.value'(newValue) {
      let value = null
      if (newValue !== null) {
        value = newValue + ' 00:00:00'
      }
      
      this.filters['created_at[after]'] = value
    },
    'datePickers.to.value'(newValue) {
      let value = null
      if (newValue !== null) {
        value = newValue + ' 23:59:59'
      }
      
      this.filters['created_at[before]'] = value
    },
    filters: {
      handler: debounce(function (value) {
        this.$emit('onFiltersChanged', value)
      }, 500),
      deep: true,
    },
  },
}
</script>

<style scoped>

</style>
